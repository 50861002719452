class ᴥButton extends HTMLElement {
  
  button: HTMLButtonElement | undefined = undefined;

  constructor() {
    super();
  }


  doChange(theme: string){
    const antiTheme = theme == "light" ? 'dark' : 'light';
    document.body.classList.add("theme--"+ theme);
    document.body.classList.remove("theme--"+antiTheme);
    this.button!.setAttribute("aria-checked", theme == 'light' ? "true" : "false");
    window.sessionStorage.setItem('forced-theme', theme);
  }

  changeTheme(ev: MouseEvent){
    ev.stopPropagation();
    let isCurrentlyLight = window.matchMedia("(prefers-color-scheme: light)").matches || document.body.classList.contains("theme--light");
    this.doChange(isCurrentlyLight ? 'dark' : 'light');
  }

  connectedCallback() {
    this.innerHTML = `<button 
        role="switch"
        aria-label="Dark Mode"
        aria-checked="true"
        style="
        transition: 0.5 color;
        background-color: transparent; 
        outline: none; border: none;
        cursor: pointer;"
        >
        <span class="iconify" data-icon="mdi:lightbulb"></span>
        </button>`;

    this.button = this.querySelector('button')!;
    this.button.addEventListener("click", this.changeTheme.bind(this));
    if(window.sessionStorage.getItem('forced-theme')){
      this.doChange(window.sessionStorage.getItem('forced-theme') as string);
    }

  }
}

customElements.define("aw-ᴥ-dark-toggle", ᴥButton);
